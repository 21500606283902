import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Link from '/src/components/LocalizedLink';

import ForthcomingCard from './ForthcomingCard';

import IconArrowRightRed from '../assets/images/svg/arrow-right-red.inline.svg';

const ForthcomingCourses = ({
  title = false,
  description = false,
  cardData,
  tableData = false,
  specialHomepageOutlook = false,
  specialHomepageCardsData = false,
  specialTimeReference = false,
}) => {
  const intl = useIntl();

  const forthComingCoursesTitle = (title && intl.formatMessage(title)) || null;

  return (
    <div className="forthcoming-courses" id="courses-cards">
      {forthComingCoursesTitle && (
        <div className="forthcoming-courses__header justify-content-between">
          <h2 className="c-title-34 forthcoming-courses__title">
            {forthComingCoursesTitle}
            {description && (
              <p className="forthcoming-courses__description">
                <FormattedMessage
                  id="forthcomingCourses.description"
                  values={{
                    accent: (chunks) => (
                      <FormattedMessage id="url.courses.dates.index">
                        {(locale) => (
                          <Link to={locale[0]} className="red-link">
                            {chunks}
                          </Link>
                        )}
                      </FormattedMessage>
                    ),
                  }}
                ></FormattedMessage>
              </p>
            )}
          </h2>

          <FormattedMessage id="url.courses.dates.index">
            {(locale) => (
              <Link to={locale[0]} className="c-btn c-btn--white">
                <FormattedMessage id="homepage.sec8.seeAllPosts" />
                <IconArrowRightRed />
              </Link>
            )}
          </FormattedMessage>
        </div>
      )}
      {specialHomepageOutlook ? (
        <div className="forthcoming-courses__specials container">
          <div className="row specials">
            {specialHomepageCardsData.map((card, index) => {
              const localizedUrl = card.link;
              const formattedLocalizedUrl = `url${localizedUrl?.replace(/\//gi, '.')}${
                localizedUrl === '/courses/developing-teacher-course' ? '' : '.index'
              }`;
              return (
                <div className="col-lg-4 col-md-6 col-12 special__wrapper" key={index}>
                  <div className="card">
                    <div className="card__img">
                      {card?.image?.url && (
                        <img src={card.image.url} alt="Face-To-Face Trinity CertTESOL Course" />
                      )}
                      <h4
                        className="card__title"
                        dangerouslySetInnerHTML={{
                          __html: card?.title?.text || card?.title[0]?.text,
                        }}
                      />
                    </div>
                    <div className="card__info">
                      <p className="info__description">
                        <span className="text-fw-600">
                          <FormattedMessage id="forthcomingCourses.homepage.nextDate" />:
                        </span>{' '}
                        {card?.date}
                      </p>
                      <p className="info__description">{card?.description}</p>
                      {card.link && (
                        <>
                          {/* <Link to={card.link} className="c-btn c-btn--red-border">
                            Find Out More
                            <IconArrowRightRed />
                          </Link> */}

                          <FormattedMessage id={formattedLocalizedUrl}>
                            {(locale) => (
                              <Link to={locale[0]} className="c-btn c-btn--red-border">
                                <FormattedMessage id="homepage.sec7.i.findOutMore" />
                                <IconArrowRightRed />
                              </Link>
                            )}
                          </FormattedMessage>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="forthcoming-courses__table">
          <div className="row cell cell--title">
            <div className="col-2 c-text-14">
              {tableData.course || <FormattedMessage id="forthcomingCourses.course" />}
            </div>
            <div className="col-2 c-text-14">
              {tableData.from || <FormattedMessage id="forthcomingCourses.from" />}
            </div>
            <div className="col-2 c-text-14">
              {tableData.to || <FormattedMessage id="forthcomingCourses.to" />}
            </div>
            <div className="col-2 c-text-14">
              {tableData.price || <FormattedMessage id="forthcomingCourses.price" />}
            </div>
          </div>
          {cardData.map(({ node }) => (
            <ForthcomingCard key={node.id} specialTimeReference={specialTimeReference} cardData={node.data} tableData={tableData} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ForthcomingCourses;
